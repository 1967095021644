import { default as signed_45inF5mO0E428jMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/auth/signed-in.vue?macro=true";
import { default as _91slug_93pe6LkvePR1Meta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/blog/[slug].vue?macro=true";
import { default as indexsVRyqcv8FPMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/blog/category/[category1]/[category2]/[category3]/index.vue?macro=true";
import { default as indexsKc2qfjC9qMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/blog/category/[category1]/[category2]/index.vue?macro=true";
import { default as index2o1mhfqpF0Meta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/blog/category/[category1]/index.vue?macro=true";
import { default as indexVVisOQie6XMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/blog/index.vue?macro=true";
import { default as _91slug_93SEcn3RrTWtMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/brands/[slug].vue?macro=true";
import { default as indexRTFckuu0VIMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/brands/index.vue?macro=true";
import { default as _91category_93P1jaLTgo22Meta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/c/[category].vue?macro=true";
import { default as index0XUYuqUSquMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/cart/index.vue?macro=true";
import { default as indexw3bvMLTlGpMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/catalog/index.vue?macro=true";
import { default as footerxSWPXpYItLMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/iframe/footer.vue?macro=true";
import { default as headerjdBwDJTKWWMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/iframe/header.vue?macro=true";
import { default as store_45locatorWDl7eXVtUWMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/iframe/store-locator.vue?macro=true";
import { default as indexhrvurFqo1mMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/index.vue?macro=true";
import { default as _91slug_93Xuu88t2EZvMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/legal/[slug].vue?macro=true";
import { default as maintenanceYOsueotDS1Meta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/maintenance.vue?macro=true";
import { default as indexOngXfGUjNdMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-addresses/index.vue?macro=true";
import { default as index8V3l6nThNEMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-information/index.vue?macro=true";
import { default as indexLq0fkyWE0PMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-loyalty-card/index.vue?macro=true";
import { default as index6la501AEsdMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-loyalty-program/index.vue?macro=true";
import { default as indexQm67zbmWnjMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-loyalty/index.vue?macro=true";
import { default as _91slug_93g9u9u6UIvyMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-orders/[slug].vue?macro=true";
import { default as indexFYLXKicmXGMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-orders/index.vue?macro=true";
import { default as indexxH3gYrQZabMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-store/index.vue?macro=true";
import { default as my_45accountF2hZcAXsT1Meta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account.vue?macro=true";
import { default as indexpccjHnm3TMMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/order/confirmation/index.vue?macro=true";
import { default as order46JRTM8q5hMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/order.vue?macro=true";
import { default as _91id_93S2WViyaB12Meta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/p/[id].vue?macro=true";
import { default as _91slug_93g8mZJXe19lMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/preview/article/[slug].vue?macro=true";
import { default as _91slug_93hwCUUpXhQKMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/preview/brand/[slug].vue?macro=true";
import { default as _91id_93aL14qXvNsQMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/preview/home/[id].vue?macro=true";
import { default as _91categorie1_93uxLTsSD9ieMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/preview/landing/[categorie1].vue?macro=true";
import { default as indexKGCiHHXqKxMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/promotions/index.vue?macro=true";
import { default as indexpVsGiFNbrqMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/purchase/delivery/index.vue?macro=true";
import { default as index5BRbcEkDQAMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/purchase/payment/index.vue?macro=true";
import { default as purchaseYnlqdfwMjdMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/purchase.vue?macro=true";
import { default as indexIGq5IgfMMuMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/redirection-login/index.vue?macro=true";
import { default as sandboxzclgc9gYeVMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/sandbox.vue?macro=true";
import { default as index8Ei7LmhFoyMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/search/index.vue?macro=true";
import { default as _91slug_93z9e2P28qfFMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/sellers/[slug].vue?macro=true";
import { default as indexMaC96VnwiZMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/sitemap/index.vue?macro=true";
import { default as _91slug_93MwlgWmNFZcMeta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/stores/[slug].vue?macro=true";
import { default as indexbLbxVRopn1Meta } from "/builds/invivoretail/enseignes/enseignes.com/src/application/pages/stores/index.vue?macro=true";
import { default as component_45stubvW5bUwnN0jMeta } from "/builds/invivoretail/enseignes/enseignes.com/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubvW5bUwnN0j } from "/builds/invivoretail/enseignes/enseignes.com/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "auth-signed-in___fr",
    path: "/auth/signed-in",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/auth/signed-in.vue")
  },
  {
    name: "auth-signed-in___es",
    path: "/es/auth/signed-in",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/auth/signed-in.vue")
  },
  {
    name: "blog-slug___fr",
    path: "/conseils-idees/:slug()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/blog/[slug].vue")
  },
  {
    name: "blog-slug___es",
    path: "/es/consejos-ideas/:slug()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/blog/[slug].vue")
  },
  {
    name: "blog-category-category1-category2-category3___fr",
    path: "/conseils-idees/categorie/:category1()/:category2()/:category3()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/blog/category/[category1]/[category2]/[category3]/index.vue")
  },
  {
    name: "blog-category-category1-category2-category3___es",
    path: "/es/consejos-ideas/categoria/:category1()/:category2()/:category3()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/blog/category/[category1]/[category2]/[category3]/index.vue")
  },
  {
    name: "blog-category-category1-category2___fr",
    path: "/conseils-idees/categorie/:category1()/:category2()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/blog/category/[category1]/[category2]/index.vue")
  },
  {
    name: "blog-category-category1-category2___es",
    path: "/es/consejos-ideas/categoria/:category1()/:category2()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/blog/category/[category1]/[category2]/index.vue")
  },
  {
    name: "blog-category-category1___fr",
    path: "/conseils-idees/categorie/:category1()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/blog/category/[category1]/index.vue")
  },
  {
    name: "blog-category-category1___es",
    path: "/es/consejos-ideas/categoria/:category1()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/blog/category/[category1]/index.vue")
  },
  {
    name: "blog___fr",
    path: "/conseils-idees",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/blog/index.vue")
  },
  {
    name: "blog___es",
    path: "/es/consejos-ideas",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/blog/index.vue")
  },
  {
    name: "brands-slug___fr",
    path: "/marques/:slug()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/brands/[slug].vue")
  },
  {
    name: "brands-slug___es",
    path: "/es/marcas/:slug()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/brands/[slug].vue")
  },
  {
    name: "brands___fr",
    path: "/nos-marques",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/brands/index.vue")
  },
  {
    name: "brands___es",
    path: "/es/nuestras-marcas",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/brands/index.vue")
  },
  {
    name: "c-category___fr",
    path: "/c/:category()",
    meta: _91category_93P1jaLTgo22Meta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/c/[category].vue")
  },
  {
    name: "c-category___es",
    path: "/es/c/:category()",
    meta: _91category_93P1jaLTgo22Meta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/c/[category].vue")
  },
  {
    name: "cart___fr",
    path: "/panier",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/cart/index.vue")
  },
  {
    name: "cart___es",
    path: "/es/carrito",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/cart/index.vue")
  },
  {
    name: "catalog___fr",
    path: "/catalogue",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/catalog/index.vue")
  },
  {
    name: "catalog___es",
    path: "/es/catalogo",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/catalog/index.vue")
  },
  {
    name: "iframe-footer___fr",
    path: "/iframe/footer",
    meta: footerxSWPXpYItLMeta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/iframe/footer.vue")
  },
  {
    name: "iframe-footer___es",
    path: "/es/iframe/footer",
    meta: footerxSWPXpYItLMeta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/iframe/footer.vue")
  },
  {
    name: "iframe-header___fr",
    path: "/iframe/header",
    meta: headerjdBwDJTKWWMeta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/iframe/header.vue")
  },
  {
    name: "iframe-header___es",
    path: "/es/iframe/header",
    meta: headerjdBwDJTKWWMeta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/iframe/header.vue")
  },
  {
    name: "iframe-store-locator___fr",
    path: "/iframe/store-locator",
    meta: store_45locatorWDl7eXVtUWMeta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/iframe/store-locator.vue")
  },
  {
    name: "iframe-store-locator___es",
    path: "/es/iframe/store-locator",
    meta: store_45locatorWDl7eXVtUWMeta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/iframe/store-locator.vue")
  },
  {
    name: "index___fr",
    path: "/",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/index.vue")
  },
  {
    name: "legal-slug___fr",
    path: "/legal/:slug()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/legal/[slug].vue")
  },
  {
    name: "legal-slug___es",
    path: "/es/legal/:slug()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/legal/[slug].vue")
  },
  {
    name: "maintenance___fr",
    path: "/maintenance",
    meta: maintenanceYOsueotDS1Meta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/maintenance.vue")
  },
  {
    name: "maintenance___es",
    path: "/es/maintenance",
    meta: maintenanceYOsueotDS1Meta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/maintenance.vue")
  },
  {
    name: "my-account___fr",
    path: "/mon-compte",
    meta: my_45accountF2hZcAXsT1Meta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account.vue"),
    children: [
  {
    name: "my-account-my-addresses___fr",
    path: "mes-adresses",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-addresses/index.vue")
  },
  {
    name: "my-account-my-information___fr",
    path: "mes-informations",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-information/index.vue")
  },
  {
    name: "my-account-my-loyalty-card___fr",
    path: "ma-carte-fidelite",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-loyalty-card/index.vue")
  },
  {
    name: "my-account-my-loyalty-program___fr",
    path: "mon-nouveau-programme-fidelite",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-loyalty-program/index.vue")
  },
  {
    name: "my-account-my-loyalty___fr",
    path: "mon-programme-fidelite",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-loyalty/index.vue")
  },
  {
    name: "my-account-my-orders-slug___fr",
    path: "mes-commandes/:slug()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-orders/[slug].vue")
  },
  {
    name: "my-account-my-orders___fr",
    path: "mes-commandes",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-orders/index.vue")
  },
  {
    name: "my-account-my-store___fr",
    path: "mon-magasin",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-store/index.vue")
  }
]
  },
  {
    name: "my-account___es",
    path: "/es/mi-cuenta",
    meta: my_45accountF2hZcAXsT1Meta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account.vue"),
    children: [
  {
    name: "my-account-my-addresses___es",
    path: "mis-direcciones",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-addresses/index.vue")
  },
  {
    name: "my-account-my-information___es",
    path: "mis-informaciones",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-information/index.vue")
  },
  {
    name: "my-account-my-loyalty-card___es",
    path: "mi-tarjeta-de-fidelizacion",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-loyalty-card/index.vue")
  },
  {
    name: "my-account-my-loyalty-program___es",
    path: "mi-nuevo-programa-de-fidelidad",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-loyalty-program/index.vue")
  },
  {
    name: "my-account-my-loyalty___es",
    path: "mi-programa-de-fidelidad",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-loyalty/index.vue")
  },
  {
    name: "my-account-my-orders-slug___es",
    path: "mis-ordenes/:slug()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-orders/[slug].vue")
  },
  {
    name: "my-account-my-orders___es",
    path: "mis-ordenes",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-orders/index.vue")
  },
  {
    name: "my-account-my-store___es",
    path: "mi-tienda",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/my-account/my-store/index.vue")
  }
]
  },
  {
    name: "order___fr",
    path: "/order",
    meta: order46JRTM8q5hMeta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/order.vue"),
    children: [
  {
    name: "order-confirmation___fr",
    path: "/commande/confirmation",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/order/confirmation/index.vue")
  }
]
  },
  {
    name: "order___es",
    path: "/es/order",
    meta: order46JRTM8q5hMeta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/order.vue"),
    children: [
  {
    name: "order-confirmation___es",
    path: "/es/orden/confirmacion",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/order/confirmation/index.vue")
  }
]
  },
  {
    name: "p-id___fr",
    path: "/p/:id()",
    meta: _91id_93S2WViyaB12Meta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/p/[id].vue")
  },
  {
    name: "p-id___es",
    path: "/es/p/:id()",
    meta: _91id_93S2WViyaB12Meta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/p/[id].vue")
  },
  {
    name: "preview-article-slug___fr",
    path: "/preview/article/:slug()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/preview/article/[slug].vue")
  },
  {
    name: "preview-article-slug___es",
    path: "/es/preview/article/:slug()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/preview/article/[slug].vue")
  },
  {
    name: "preview-brand-slug___fr",
    path: "/preview/brand/:slug()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/preview/brand/[slug].vue")
  },
  {
    name: "preview-brand-slug___es",
    path: "/es/preview/brand/:slug()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/preview/brand/[slug].vue")
  },
  {
    name: "preview-home-id___fr",
    path: "/preview/home/:id()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/preview/home/[id].vue")
  },
  {
    name: "preview-home-id___es",
    path: "/es/preview/home/:id()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/preview/home/[id].vue")
  },
  {
    name: "preview-landing-categorie1___fr",
    path: "/preview/landing/:categorie1()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/preview/landing/[categorie1].vue")
  },
  {
    name: "preview-landing-categorie1___es",
    path: "/es/preview/landing/:categorie1()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/preview/landing/[categorie1].vue")
  },
  {
    name: "promotions___fr",
    path: "/promotions",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/promotions/index.vue")
  },
  {
    name: "promotions___es",
    path: "/es/promociones",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/promotions/index.vue")
  },
  {
    name: "purchase___fr",
    path: "/purchase",
    meta: purchaseYnlqdfwMjdMeta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/purchase.vue"),
    children: [
  {
    name: "purchase-delivery___fr",
    path: "/achat/livraison",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/purchase/delivery/index.vue")
  },
  {
    name: "purchase-payment___fr",
    path: "/achat/paiement",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/purchase/payment/index.vue")
  }
]
  },
  {
    name: "purchase___es",
    path: "/es/purchase",
    meta: purchaseYnlqdfwMjdMeta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/purchase.vue"),
    children: [
  {
    name: "purchase-delivery___es",
    path: "/es/compra/entrega",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/purchase/delivery/index.vue")
  },
  {
    name: "purchase-payment___es",
    path: "/es/compra/pago",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/purchase/payment/index.vue")
  }
]
  },
  {
    name: "redirection-login___fr",
    path: "/redirection-login",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/redirection-login/index.vue")
  },
  {
    name: "redirection-login___es",
    path: "/es/redirection-login",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/redirection-login/index.vue")
  },
  {
    name: "sandbox___fr",
    path: "/sandbox",
    meta: sandboxzclgc9gYeVMeta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/sandbox.vue")
  },
  {
    name: "sandbox___es",
    path: "/es/sandbox",
    meta: sandboxzclgc9gYeVMeta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/sandbox.vue")
  },
  {
    name: "search___fr",
    path: "/recherche",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/search/index.vue")
  },
  {
    name: "search___es",
    path: "/es/busqueda",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/search/index.vue")
  },
  {
    name: "sellers-slug___fr",
    path: "/vendeurs/:slug()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/sellers/[slug].vue")
  },
  {
    name: "sellers-slug___es",
    path: "/es/vendedores/:slug()",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/sellers/[slug].vue")
  },
  {
    name: "sitemap___fr",
    path: "/plan-du-site",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/sitemap/index.vue")
  },
  {
    name: "sitemap___es",
    path: "/es/plano-del-sitio",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/sitemap/index.vue")
  },
  {
    name: "stores-slug___fr",
    path: "/magasins/:slug()",
    meta: _91slug_93MwlgWmNFZcMeta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/stores/[slug].vue")
  },
  {
    name: "stores-slug___es",
    path: "/es/tiendas/:slug()",
    meta: _91slug_93MwlgWmNFZcMeta || {},
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/stores/[slug].vue")
  },
  {
    name: "stores___fr",
    path: "/magasins",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/stores/index.vue")
  },
  {
    name: "stores___es",
    path: "/es/tiendas",
    component: () => import("/builds/invivoretail/enseignes/enseignes.com/src/application/pages/stores/index.vue")
  },
  {
    name: component_45stubvW5bUwnN0jMeta?.name,
    path: "/conseils/sites/default/files/:pathMatch(.*)",
    component: component_45stubvW5bUwnN0j
  }
]